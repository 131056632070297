function getCsIdTsg (authData: any, managedAppName: string) {
  const VnRAssociatedApps = [
    "logging_service",
    "strata_insights_free",
    "strata_insights",
    "strata_insights_ngfw",
    "prisma_access",
    "prisma_access_onprem",
    "cgx",
    "strata_cloud_manager"
  ];
  let appInfo = authData.instances.get(managedAppName);
  if (!appInfo) {
    const cspAssociation = VnRAssociatedApps.find(app => authData?.instances?.get(app)?.support_account_id !== undefined);
    appInfo = authData.instances.get(cspAssociation);
  }

  return appInfo == null ? undefined : appInfo.support_account_id;
}

export default getCsIdTsg;
