export const REPORTING_ASSOCIATED_APPS = [
  "cgx",
  "strata_insights_free",
  "strata_insights",
  "prisma_access",
  "prisma_access_onprem",
  "prisma_access_panorama",
  "strata_cloud_manager"
];

export const REPORTING_ASSOCIATED_INSTANCES = [
  "strata_insights",
  "prisma_access",
  "prisma_access_onprem",
  "prisma_access_panorama",
  "strata_cloud_manager"
];

export const REPORTING_FREE_ASSOCIATED_INSTANCES = [
  "strata_insights_free",
  ...REPORTING_ASSOCIATED_INSTANCES
];
