import replaceTenantId from "./replace-tenant-id";

function getAppTenantIdTsg (authData: any, managedAppName: string) {
  const instance = authData.instances.get(managedAppName) || {};
  const tenantId = instance.tenant_id || "";

  return replaceTenantId(tenantId);
}

export default getAppTenantIdTsg;
