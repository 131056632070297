import { cloneDeep, map, reject } from "lodash";
import {
  indexTsgInstances
} from "@sparky/framework";

// We don't support 'strata_insights_free' anywhere in our apps, that's why we're mutating
// authData.instances object and rename app_id to strata_insights
export const aiopsAuthDataMutation = (
  authData: any,
  currentInstanceTier: string,
  isSCMInstanceAvail: boolean
) => {
  // Only mutate authData if it's AIOps free
  if (currentInstanceTier !== "free" && !isSCMInstanceAvail) return authData;

  const mutatedAuthData = cloneDeep(authData);
  const instances = map(
    reject(
      mutatedAuthData.instances,
      (inst: { app_id: string }) => inst.app_id === "strata_insights"
    ),
    (inst: { app_id: string }) => {
      if (inst.app_id === "strata_insights_free") {
        inst.app_id = "strata_insights";
        return inst;
      }
      if (inst.app_id === "strata_cloud_manager") {
        inst.app_id = "strata_insights";
        return inst;
      }
      return inst;
    }
  );
  mutatedAuthData.instances = indexTsgInstances(instances);
  return mutatedAuthData;
};
