import parseISO from "date-fns/parseISO";
import isPast from "date-fns/isPast";

function getLsTenantLogData(authData: any, isSCMInstanceAvail: boolean) {
  const loggingService = authData?.instances?.get("logging_service");
  // For new SCM sku if the logging service present then it is always enabled and never expired.this condition holds true always
  if (isSCMInstanceAvail && loggingService) {
    return true;
  }
  let isExpired = false;
  try {
    const expDate = parseISO(loggingService?.license_expiration);
    isExpired = isPast(expDate);
  } catch {
    console.error("getLsTenantLogData: Something went wrong");
  }
  return (
    loggingService?.extra?.purchased_size > 0 &&
    !isExpired &&
    loggingService?.sku !== "PAN-LGS-METRICS"
  );
}

export default getLsTenantLogData;
