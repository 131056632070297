import React from "react";
import {
  PDF_REPORTS_EXT_POINT,
  type TPDFReportTypeExtensionPoint,
  FieldWrapper
} from "@ui-platform/ui-core-services-extension-points";
import { getAuthState } from "@sparky/framework";
import { RadioGroup } from "@panwds/react-form";

const DEFAULT_APP_MAPPING_NAME = ["logging_service", "strata_insights", "strata_insights_free", ""];
const IAMRolesWithEditPermission = [
  "deployment_admin",
  "data_security_admin",
  "iam_admin",
  "msp_iam_admin",
  "msp_superuser",
  "network_admin",
  "security_admin",
  "soc_analyst",
  "superuser",
  "tier_1_support",
  "tier_2_support"
];

export function hasEditPermission() {
  const tsgAccess = getAuthState().access || [];
  const selectedResource = tsgAccess.filter((rule: any) =>
    DEFAULT_APP_MAPPING_NAME.includes(rule.app_id)
  );
  const roles = selectedResource.reduce((prev: any, curr: any) => [...prev, ...curr.roleNames], []);
  const roleWithExport = roles.find((role: string) => IAMRolesWithEditPermission.includes(role));

  return Boolean(roleWithExport);
}

function scheduleFormFactory(timeOptions: Array<{ value: string; children: string }>) {
  return function ScheduleForm() {
    return (
      <FieldWrapper label="Time Interval" type="radio">
        <RadioGroup
          label="Time Interval"
          showLabel={false}
          name="params.timeInterval"
          defaultValue={timeOptions[0].value}
          items={timeOptions}
          horizontal={false}
        />
      </FieldWrapper>
    );
  };
}

export default {
  point: PDF_REPORTS_EXT_POINT,
  configs: [
    {
      id: "usage",
      config: {
        name: "Prisma Access",
        report_type: "usage",
        category: "summary",
        description:
          "See how you’re leveraging what’s available to you with Prisma Access, and get a high-level view into the health and performance of your environment.",
        // link: () =>
        //   getFrameworkVars().aiops_free ? "/aiops-free/dashboards/usage" : "/dashboards/usage",
        version: 0,
        ScheduleButton: async () => await import("../components/PDFButtons/Schedule"),
        isAllowed: (state: any) => false, // Boolean(state.reporting?.reportsById?.prisma_access_report?.allowedScheduling)
        hasEditPermission
      }
    },
    {
      id: "bpa",
      config: {
        name: "Best Practices",
        report_type: "bpa",
        category: "posture",
        description:
          "Best practices include checks for the Center for Internet Security’s Critical Security Controls (CSC).",
        // link: () =>
        //   getFrameworkVars().aiops_free ? "/aiops-free/dashboards/bpa" : "/dashboards/bpa",
        version: 0,
        ScheduleButton: async () => await import("../components/PDFButtons/Schedule"),
        isAllowed: (state: any) => false, // Boolean(state.reporting?.reportsById?.best_practices_report?.allowedScheduling)
        hasEditPermission
      }
    },
    {
      id: "bpa_di",
      config: {
        name: "Best Practices",
        report_type: "bpa_di",
        category: "posture",
        description:
          "Best practices include checks for the Center for Internet Security’s Critical Security Controls (CSC).",
        // link: () =>
        //   getFrameworkVars().aiops_free ? "/aiops-free/dashboards/bpa" : "/dashboards/bpa",
        version: 0,
        ScheduleButton: async () => await import("../components/PDFButtons/Schedule"),
        isAllowed: false,
        hasEditPermission
      }
    },
    {
      id: "bpa_cloudmanager",
      config: {
        name: "Best Practices",
        report_type: "bpa_cloudmanager",
        category: "posture",
        description:
          "Best practices include checks for the Center for Internet Security’s Critical Security Controls (CSC).",
        // link: () =>
        //   getFrameworkVars().aiops_free ? "/aiops-free/dashboards/bpa" : "/dashboards/bpa",
        version: 0,
        ScheduleButton: async () => await import("../components/PDFButtons/Schedule"),
        isAllowed: false,
        hasEditPermission
      }
    },
    {
      id: "app_usage_report",
      config: {
        name: "App Usage Report",
        report_type: "app_usage_report",
        category: "Network Activity",
        description:
          "Know the security challenges associated with the applications traversing your network. Key findings here help you to refine security rules for unsanctioned and risky applications.",
        // link: () =>
        //   getFrameworkVars().aiops_free
        //     ? "/aiops-free/dashboards/app_usage"
        //     : "/dashboards/app_usage",
        version: 1,
        DownloadButton: async () => await import("../components/PDFButtons/Download"),
        ShareButton: async () => await import("../components/PDFButtons/Share"),
        ScheduleButton: async () => await import("../components/PDFButtons/Schedule"),
        isAllowed: (state: any) =>
          Boolean(state.reporting?.reportsById?.app_usage_report?.allowedScheduling),
        hasEditPermission
      }
    },
    {
      id: "user_activity_report",
      config: {
        name: "User Activity",
        report_type: "user_activity_report",
        category: "Network Activity",
        description:
          "Get visibility into an individual users’ browsing patterns: their most frequently visited sites, the sites with which they’re transferring data, and attempts to access high-risk sites.",
        // link: () =>
        //   getFrameworkVars().aiops_free ? "/aiops-free/dashboards/uar" : "/dashboards/uar",
        version: 1,
        ScheduleButton: async () => await import("../components/PDFButtons/Schedule"),
        isAllowed: (state: any) =>
          Boolean(state.reporting?.reportsById?.user_activity_report?.allowedScheduling),
        hasEditPermission
      }
    },
    {
      id: "network_activity_report",
      config: {
        name: "Network Activity",
        report_type: "network_activity_report",
        category: "activity",
        description:
          "Get a view into the different sources generating traffic in your network, and drill down to investigate any unexpected findings.",
        // link: () =>
        //   getFrameworkVars().aiops_free
        //     ? "/aiops-free/dashboards/network_activity"
        //     : "/dashboards/network_activity",
        version: 1,
        DownloadButton: async () => await import("../components/PDFButtons/Download"),
        ShareButton: async () => await import("../components/PDFButtons/Share"),
        ScheduleButton: async () => await import("../components/PDFButtons/Schedule"),
        isAllowed: (state: any) =>
          Boolean(state.reporting?.reportsById?.network_activity_report?.allowedScheduling),
        hasEditPermission
      }
    },
    {
      id: "wildfire_report",
      config: {
        name: "WildFire",
        report_type: "wildfire_report",
        category: "security",
        description:
          "WildFire protects against net new malware that’s concealed in files, executables, and email links.",
        // link: () =>
        //   getFrameworkVars().aiops_free
        //     ? "/aiops-free/dashboards/wildfire"
        //     : "/dashboards/wildfire",
        version: 1,
        DownloadButton: async () => await import("../components/PDFButtons/Download"),
        ShareButton: async () => await import("../components/PDFButtons/Share"),
        ScheduleButton: async () => await import("../components/PDFButtons/Schedule"),
        isAllowed: (state: any) =>
          Boolean(state.reporting?.reportsById?.wildfire_report?.allowedScheduling),
        hasEditPermission
      }
    },
    {
      id: "dns_report",
      config: {
        name: "DNS Security",
        report_type: "dns_report",
        category: "security",
        description:
          "DNS Security protects your network from advanced threats that use DNS. Without DNS Security in place, malware might have infiltrated your network through malicious DNS requests and remained unnoticed.",
        // link: () =>
        //   getFrameworkVars().aiops_free ? "/aiops-free/dashboards/dns" : "/dashboards/dns",
        version: 1,
        DownloadButton: async () => await import("../components/PDFButtons/Download"),
        ShareButton: async () => await import("../components/PDFButtons/Share"),
        ScheduleButton: async () => await import("../components/PDFButtons/Schedule"),
        isAllowed: (state: any) =>
          Boolean(state.reporting?.reportsById?.dns_report?.allowedScheduling),
        hasEditPermission
      }
    },
    {
      id: "advanced_threat_prevention_report",
      config: {
        name: "Advanced Threat Prevention",
        report_type: "advanced_threat_prevention_report",
        category: "security",
        description:
          "Examine the threats detected on your network and identify opportunities to strengthen your security posture.",
        // link: () =>
        //   getFrameworkVars().aiops_free
        //     ? "/aiops-free/dashboards/advanced_threat_prevention"
        //     : "/dashboards/advanced_threat_prevention",
        version: 1,
        DownloadButton: async () => await import("../components/PDFButtons/Download"),
        ShareButton: async () => await import("../components/PDFButtons/Share"),
        ScheduleButton: async () => await import("../components/PDFButtons/Schedule"),
        isAllowed: (state: any) =>
          Boolean(
            state.reporting?.reportsById?.advanced_threat_prevention_report?.allowedScheduling
          ),
        hasEditPermission
      }
    },
    {
      id: "advanced_url_filtering_report",
      config: {
        name: "Advanced URL Filtering",
        report_type: "advanced_url_filtering_report",
        category: "security",
        description:
          "Get a high-level view of the different types of activities taking place in your network recently.",
        // link: () =>
        //   getFrameworkVars().aiops_free
        //     ? "/aiops-free/dashboards/advanced_url_filtering"
        //     : "/dashboards/advanced_url_filtering",
        version: 1,
        DownloadButton: async () => await import("../components/PDFButtons/Download"),
        ShareButton: async () => await import("../components/PDFButtons/Share"),
        ScheduleButton: async () => await import("../components/PDFButtons/Schedule"),
        isAllowed: (state: any) =>
          Boolean(state.reporting?.reportsById?.advanced_url_filtering_report?.allowedScheduling),
        hasEditPermission
      }
    },
    {
      id: "executive_report_dlp_details_report",
      config: {
        name: "DLP",
        report_type: "executive_report_dlp_details_report",
        category: "activity",
        // link: () =>
        //   getFrameworkVars().aiops_free
        //     ? "/aiops-free/dashboards/dlp_details"
        //     : "/dashboards/dlp_details",
        version: 1,
        DownloadButton: async () => await import("../components/PDFButtons/Download"),
        ShareButton: async () => await import("../components/PDFButtons/Share"),
        ScheduleButton: async () => await import("../components/PDFButtons/Schedule"),
        isAllowed: (state: any) =>
          Boolean(
            state.reporting?.reportsById?.executive_report_dlp_details_report?.allowedScheduling
          ),
        hasEditPermission
      }
    },
    {
      id: "executive_report",
      config: {
        name: "Executive Summary",
        report_type: "executive_report",
        category: "summary",
        description:
          "See how your security subscriptions are protecting you. Peer data gives you perspective on your industry’s threat landscape and ━ for subscriptions you’re not using ━ shows you where you might be exposed to threats.",
        version: 1,
        DownloadButton: async () => await import("../components/PDFButtons/Download"),
        ShareButton: async () => await import("../components/PDFButtons/Share"),
        ScheduleButton: async () => await import("../components/PDFButtons/Schedule"),
        isAllowed: (state: any) => false,
        hasEditPermission
      }
    },
    {
      id: "unified_network_activity_report",
      config: {
        name: "Activity Insights - Summary",
        report_type: "unified_network_activity_report",
        category: "Network Activity",
        description:
          "Monitor traffic usage, and view the top applications, threats, users, URLs, and rules in your network.",
        version: 1,
        DownloadButton: async () => await import("../components/PDFButtons/Download"),
        ShareButton: async () => await import("../components/PDFButtons/Share"),
        ScheduleButton: async () => await import("../components/PDFButtons/Schedule"),
        isAllowed: (state: any) => false,
        hasEditPermission
      }
    },
    {
      id: "vnr.executive",
      config: {
        name: "Executive Summary",
        description:
          "See how your security subscriptions are protecting you. Peer data gives you perspective on your industry’s threat landscape and ━ for subscriptions you’re not using ━ shows you where you might be exposed to threats.",
        report_type: "vnr.executive",
        category: "summary",
        isAllowed: (state: any) =>
          Boolean(state.reporting?.reportsById?.executive_report?.allowedScheduling),
        version: 2,
        params: () => ({ timeInterval: "30d" }),
        ScheduleForm: async () => ({
          default: scheduleFormFactory([
            { value: "30d", children: "Past 30 days" },
            { value: "90d", children: "Past 90 days" }
          ])
        })
      }
    },
    {
      id: "vnr.activity_insights",
      config: {
        name: "Activity Insights - Summary",
        description:
          "Monitor traffic usage, and view the top applications, threats, users, URLs, and rules in your network.",
        report_type: "vnr.activity_insights",
        category: "summary",
        isAllowed: (state: any) =>
          Boolean(state.reporting?.reportsById?.unified_network_activity_report?.allowedScheduling),
        version: 2,
        params: () => ({ timeInterval: "24h" }),
        ScheduleForm: async () => ({
          default: scheduleFormFactory([
            { value: "24h", children: "Past 24 hours" },
            { value: "7d", children: "Past 7 days" },
            { value: "30d", children: "Past 30 days" }
          ])
        })
      }
    }
  ]
} satisfies TPDFReportTypeExtensionPoint;
