import { getAuthState } from "@sparky/framework";
import { isNil } from "lodash";

function getMfeProperties() {
  const properties = getAuthState()?.properties;
  const isPanoramaManaged = getAuthState?.()?.instances?.get("prisma_access")?.extra?.platform === "onprem";
  if (isNil(properties)) {
    return {};
  }
  return {
    serverFQDNS: Object.keys(properties?.get("vnr.fqdns") ?? {})?.length
      ? properties.get("vnr.fqdns")
      : null,
    featureFlags: Object.keys(properties?.get("vnr.featureFlags") ?? {})?.length
      ? properties.get("vnr.featureFlags")
      : null,
    fawkesLicense: (Object.keys(properties?.get("fawkes.license") ?? {})?.length && !isPanoramaManaged)
      ? properties.get("fawkes.license")
      : null,
    licenseSummary: Object.keys(properties?.get("vnr.licenseSummaryTsg") ?? {})?.length
      ? properties.get("vnr.licenseSummaryTsg")
      : null,
    isTsgCsp: Object.keys(properties?.get("vnr.tsgCsp") ?? {})?.length
      ? properties.get("vnr.tsgCsp")?.tsgCsp
      : null
  };
}

export default getMfeProperties;
