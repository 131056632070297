import get from "lodash/get";
import find from "lodash/find";

function getLsTenantId (main: any) {
  const extensions = get(main, "tenant.extensions", []);
  const tenantExt = find(extensions, extension => extension.name === "Logging Service");
  const rawInfo = get(tenantExt, "info", null);

  if (rawInfo) {
    const info = JSON.parse(rawInfo);

    return info ? info.tenant_id : undefined;
  }

  return undefined;
}

export default getLsTenantId;
