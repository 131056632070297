import { getFrameworkServiceUrl, getAuthState, getMicroAppVars } from "@sparky/framework";
import get from "lodash/get";

function getPaParams(hasTsgSupport: boolean, main: any, StartupManager: any) {
  const isFawkesRunning = getAuthState()?.instances?.isFawkesRunning ?? false;
  const paEnabled = get(main, "tenant.pa_enabled", isFawkesRunning);
  const paiVars = getMicroAppVars('pai') ?? {};
  const paiServer = paiVars.apiServer?.replace(/(^\w+:|^)\/\//, "");
  const cosmoFQDN = getFrameworkServiceUrl("cosmos")?.replace(/(^\w+:|^)\/\//, "") ?? "";
  return hasTsgSupport
    ? {
        paEnabled,
        paiFQDN: paiServer ?? cosmoFQDN
      }
    : {
        paEnabled,
        paiFQDN: get(StartupManager.getReportingFQDNs(), "paiFQDN", cosmoFQDN)
      };
}

export default getPaParams;
