/*
    DI isn't putting the tenant region info in main state anymore
    It's under TSG Auth data, instance of strata_insights/strata_insights_free
 */
const getDIAppTenantRegion = (managedAppName: any, authData: { instances: any[] }) => {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const managedAppInstance = authData?.instances?.find(({ app_id }) => app_id === managedAppName);
  return managedAppInstance?.region;
};

export default getDIAppTenantRegion;
