import { getFrameworkVars, getAuthState } from "@sparky/framework";

export const AIOPS_FREE_PATH = "/aiops-free";

/**
 * SCM now supports new SKU's SCM Base and Premium whose app_id is strata_cloud_manager
 * if this app id contains entitlements then it is an SCM premium tenant
 * if this app id does not contain entitlements then it is an SCM Base tenant
 * Both old and new skus should be supported
 * */
function getFrameworkInstance () {
  const authData = getAuthState();
  const scmInstance = authData?.instances?.get("strata_cloud_manager");

  // For new P&P sku's
  if (scmInstance) {
    return scmInstance?.entitlements?.length ? "premium" : "free";
  }

  // old sku's for strata insighst free and premium
  if (getFrameworkVars().aiops_free && authData.instances?.isRunningStatus("strata_insights_free")) {
    // aiops free fqdn
    return "free";
  } else if (!getFrameworkVars().aiops_free && authData.instances?.isRunningStatus("strata_insights_free") && !authData.instances?.isRunningStatus("strata_insights")) {
    // scenario where a tenant with aiops free + other instances loads in SCM instead of aiops free domain
    // in this scenario the tenant has aiops free instance but getFrameworkVars().aiops_free is false
    // HybridFramework
    return "free";
  } else {
    // fallback
    return getFrameworkVars().aiops_free ? "free" : "premium";
  }
}

function getInstanceTier () {
  return {
    frameworkInstance: getFrameworkInstance(),
    // scenario where a tenant with aiops free + other instances loads in SCM instead of aiops free domain
    // in this scenario the tenant has aiops free instance but getFrameworkVars().aiops_free is false
    isHybridFramework: getAuthState().instances?.isRunningStatus("strata_insights_free") && !getFrameworkVars().aiops_free,
    // New Pricing & Packaging(P&P) instance
    hasSCMInstance: getAuthState().instances?.isRunningStatus("strata_cloud_manager")
  };
}
export default getInstanceTier;
