import { REPORTING_ASSOCIATED_INSTANCES, REPORTING_FREE_ASSOCIATED_INSTANCES } from "../ReportingAssociatedApps";

function getSdwanOnlyFlag(authData: any, currentInstanceTier: "free" | "premium"): boolean {
  const instances: Array<{ app_id: string }> = authData?.instances ?? [];
  const vnrInstances =
    currentInstanceTier === "free"
      ? REPORTING_FREE_ASSOCIATED_INSTANCES
      : REPORTING_ASSOCIATED_INSTANCES;
  const result = instances.reduce(
    (acc, curr) => {
      return {
        hasSDWAN: acc.hasSDWAN || curr.app_id === "cgx",
        hasSupportedEnv: acc.hasSupportedEnv || vnrInstances.includes(curr.app_id)
      };
    },
    { hasSDWAN: false, hasSupportedEnv: false }
  );
  return result.hasSDWAN && !result.hasSupportedEnv;
}

export default getSdwanOnlyFlag;
